import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Paper,
  Typography,
  TextField,
  IconButton,
  Avatar,
  Fade,
  Fab,
  CircularProgress,
  styled,
  useTheme,
} from "@mui/material";

import { MdSend as SendIcon } from "react-icons/md";
import { CiChat1 as ChatBubbleOutlineIcon } from "react-icons/ci";
import { IoMdCloseCircle as CloseIcon } from "react-icons/io";

import axios from "axios";
import { BASE_URL } from "../constants";

// Styled components
const ChatWindow = styled(Paper)(({ theme }) => ({
  position: "fixed",
  bottom: 90,
  right: 20,
  width: 350,
  height: 500,
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  boxShadow: theme.shadows[10],
  borderRadius: theme.shape.borderRadius,
  zIndex: 1000,
}));

const ChatHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const ChatBody = styled(Box)(({ theme }) => ({
  flex: 1,
  padding: theme.spacing(2),
  overflowY: "auto",
  backgroundColor: theme.palette.background.default,
  "&::-webkit-scrollbar": {
    width: "6px",
  },
  "&::-webkit-scrollbar-track": {
    background: "transparent",
  },
  "&::-webkit-scrollbar-thumb": {
    background: theme.palette.grey[300],
    borderRadius: "6px",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: theme.palette.grey[400],
  },
  scrollbarWidth: "thin",
  scrollbarColor: `${theme.palette.grey[300]} transparent`,
}));

const ChatFooter = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  borderTop: `1px solid ${theme.palette.divider}`,
  display: "flex",
  alignItems: "center",
}));

const MessageBubble = styled(Box)(({ theme, isUser }) => ({
  maxWidth: "80%",
  padding: theme.spacing(1.5),
  borderRadius: 16,
  marginBottom: theme.spacing(1.5),
  wordBreak: "break-word",
  backgroundColor: isUser ? theme.palette.grey[50] : theme.palette.grey[200],
  color: theme.palette.primary.primary,
  alignSelf: isUser ? "flex-end" : "flex-start",
  display: "flex",
  flexDirection: "column",
}));

const ChatToggleButton = styled(Fab)(({ theme }) => ({
  position: "fixed",
  bottom: 20,
  right: 20,
  zIndex: 1000,
}));

const ChatContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%",
});

const ChatFooterBranding = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  borderTop: `1px solid ${theme.palette.grey[100]}`,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: theme.palette.background.paper,
  fontSize: "0.85rem",
  color: theme.palette.text.secondary,
  "& a": {
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontWeight: 500,
    marginLeft: "4px",
    transition: "color 0.2s ease",
    "&:hover": {
      color: theme.palette.primary.dark,
      textDecoration: "underline",
    },
  },
}));

const Chat = ({ chatbotData, chatbotId, domain }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userMessages, setUserMessages] = useState([]);
  const [input, setInput] = useState("");
  const theme = useTheme();
  const messageEndRef = useRef(null);
  const inputRef = useRef(null);

  const { greetingsText, initialText, name, logo } = chatbotData;

  let botUrl = "";
  if (logo && logo.imgSrc) {
    botUrl = logo.imgSrc;
  }

  // Convert from the format in your original component to a more standardized format
  const [messages, setMessages] = useState([
    {
      id: Date.now(),
      text: name || "SMART AI が会話に参加しました",
      type: "notification",
      timestamp: new Date(),
    },
    {
      id: Date.now() + 1,
      text: initialText || "Hi, how can I help you?",
      sender: "bot",
      type: "text",
      timestamp: new Date(),
    },
  ]);

  // Auto-scroll to bottom of messages
  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // Focus on input when chat opens
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 300);
    }
  }, [isOpen]);

  const handleSendMessage = async (messageText) => {
    if (!messageText.trim()) return;

    if (messageText.length > 200) {
      const newMessage = {
        id: Date.now(),
        text: "メッセージが長すぎます。申し訳ありませんが、もう一度お試しください。",
        sender: "user",
        type: "text",
        timestamp: new Date(),
      };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      setInput("");
      return;
    }

    // Add user message to chat
    const newMessage = {
      id: Date.now(),
      text: messageText,
      sender: "user",
      type: "text",
      timestamp: new Date(),
    };

    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setInput("");
    setLoading(true);

    try {
      const response = await axios.post(BASE_URL + "/public/query", {
        message: messageText,
        chatbotId,
        history: userMessages.slice(-10),
        domain,
      });

      setUserMessages([...userMessages, messageText]); // Update user messages history

      const botReply = {
        id: Date.now() + 1,
        text: response.data?.text || response.data.message,
        sender: "bot",
        type: "text",
        timestamp: new Date(),
      };

      setMessages((prevMessages) => [...prevMessages, botReply]);
    } catch (error) {
      console.log("API error", error.response);
      const rateLimitRemaining =
        error.response?.headers["x-ratelimit-remaining"];

      if (rateLimitRemaining <= 0) {
        const errorMessage = {
          id: Date.now() + 1,
          text: "**You have finished all attempts, please try again later",
          sender: "bot",
          type: "text",
          timestamp: new Date(),
        };

        setMessages((prevMessages) => [...prevMessages, errorMessage]);
      } else {
        const errorMessage = {
          id: Date.now() + 1,
          text: "Sorry, I encountered an error. Please try again.",
          sender: "bot",
          type: "text",
          timestamp: new Date(),
        };

        setMessages((prevMessages) => [...prevMessages, errorMessage]);
      }

      console.error("Failed to send/receive the message:", error);
    }

    setLoading(false);
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(input);
    }
  };

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const formatTimestamp = (timestamp) => {
    return new Intl.DateTimeFormat("en-US", {
      hour: "numeric",
      minute: "numeric",
    }).format(timestamp);
  };

  return (
    <div>
      <ChatToggleButton
        color="primary"
        onClick={toggleChat}
        aria-label={isOpen ? "Close chat" : "Open chat"}
      >
        {isOpen ? (
          <CloseIcon style={{ fontSize: "1.5rem" }} />
        ) : logo?.imgSrc ? (
          <Avatar
            src={logo.imgSrc}
            alt={name || "Chatbot"}
            sx={{ width: 60, height: 60 }}
          />
        ) : (
          <ChatBubbleOutlineIcon style={{ fontSize: "1.5rem" }} />
        )}
      </ChatToggleButton>

      <Fade in={isOpen}>
        <ChatWindow elevation={3}>
          <ChatHeader>
            <Box display="flex" alignItems="center">
              <Avatar
                src={botUrl}
                alt={name || "Chatbot"}
                sx={{ marginRight: 1, width: 36, height: 36 }}
              >
                {!botUrl && (name ? name.charAt(0) : "A")}
              </Avatar>
              <Typography variant="h6">{name || "AI Assistant"}</Typography>
            </Box>
            <IconButton
              color="inherit"
              onClick={toggleChat}
              aria-label="Close chat"
              size="small"
            >
              <CloseIcon style={{ fontSize: "1.5rem" }} />
            </IconButton>
          </ChatHeader>

          {greetingsText && (
            <Box
              sx={{
                padding: 2,
                borderBottom: `1px solid ${theme.palette.divider}`,
                backgroundColor: theme.palette.background.paper,
              }}
            >
              <Typography variant="body2">{greetingsText}</Typography>
            </Box>
          )}

          <ChatBody>
            <ChatContainer>
              {messages.map((message) =>
                message.type === "notification" ? (
                  <Box
                    key={message.id}
                    sx={{
                      textAlign: "center",
                      margin: "8px 0",
                    }}
                  >
                    <Typography
                      variant="caption"
                      sx={{
                        backgroundColor: theme.palette.grey[200],
                        padding: "4px 8px",
                        borderRadius: "8px",
                      }}
                    >
                      {message.text}
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    key={message.id}
                    sx={{
                      display: "flex",
                      justifyContent:
                        message.sender === "user" ? "flex-end" : "flex-start",
                      mb: 2,
                    }}
                  >
                    {message.sender === "bot" && (
                      <Avatar
                        src={botUrl}
                        alt={name || "Chatbot"}
                        sx={{
                          mr: 1,
                          width: 32,
                          height: 32,
                          alignSelf: "flex-end",
                        }}
                      >
                        {!botUrl && (name ? name.charAt(0) : "A")}
                      </Avatar>
                    )}

                    <MessageBubble isUser={message.sender === "user"}>
                      <Typography variant="body1">{message.text}</Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          alignSelf:
                            message.sender === "user"
                              ? "flex-start"
                              : "flex-end",
                          opacity: 0.7,
                          mt: 0.5,
                        }}
                      >
                        {formatTimestamp(message.timestamp)}
                      </Typography>
                    </MessageBubble>

                    {message.sender === "user" && (
                      <Avatar
                        alt="You"
                        sx={{
                          ml: 1,
                          width: 32,
                          height: 32,
                          alignSelf: "flex-end",
                        }}
                      >
                        U
                      </Avatar>
                    )}
                  </Box>
                )
              )}
              {loading && (
                <Box
                  sx={{ display: "flex", justifyContent: "flex-start", mb: 2 }}
                >
                  <Avatar
                    src={botUrl}
                    alt={name || "Chatbot"}
                    sx={{ mr: 1, width: 32, height: 32 }}
                  >
                    {!botUrl && (name ? name.charAt(0) : "A")}
                  </Avatar>
                  <Box
                    sx={{
                      p: 1.5,
                      borderRadius: 2,
                      backgroundColor: theme.palette.grey[100],
                    }}
                  >
                    <CircularProgress size={20} />
                  </Box>
                </Box>
              )}
              <div ref={messageEndRef} />
            </ChatContainer>
          </ChatBody>

          <ChatFooter
            sx={{
              padding: "12px 16px",
              backgroundColor: theme.palette.background.paper,
            }}
          >
            <TextField
              inputRef={inputRef}
              fullWidth
              placeholder="Type a message..."
              variant="outlined"
              size="small"
              value={input}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              multiline
              maxRows={3}
              sx={{
                mr: 1,
                "& .MuiOutlinedInput-root": {
                  borderRadius: "24px",
                  backgroundColor: theme.palette.grey[50],
                  boxShadow: "0 1px 3px rgba(0,0,0,0.05)",
                  "& fieldset": {
                    borderColor: "transparent",
                  },
                  "&:hover fieldset": {
                    borderColor: theme.palette.primary.light,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: theme.palette.primary.main,
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "5px 5px",
                  },
                },
              }}
            />
            <IconButton
              color="primary"
              onClick={() => handleSendMessage(input)}
              disabled={!input.trim() || loading}
              sx={{
                backgroundColor: input.trim()
                  ? "rgba(25, 118, 210, 0.12)"
                  : "transparent",
                borderRadius: "50%",
                padding: "8px",
                "&:hover": {
                  backgroundColor: input.trim()
                    ? "rgba(25, 118, 210, 0.2)"
                    : "rgba(0, 0, 0, 0.04)",
                },
              }}
            >
              <SendIcon />
            </IconButton>
          </ChatFooter>

          <ChatFooterBranding>
            Powered by
            <a
              href="https://ncchatbot.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              NCC Chatbot
            </a>
          </ChatFooterBranding>
        </ChatWindow>
      </Fade>
    </div>
  );
};

export default Chat;
